export default {
    Date(data) {
        let new_date = new Date(); //新建一个日期对象，默认现在的时间
        let old_date = new Date(data);
        let difftime = (Number(new_date) - Number(old_date)) / 1000; //计算时间差,并把毫秒转换成秒
        let days = parseInt(Number(difftime) / 86400); // 天  24*60*60*1000
        let hours = parseInt(difftime / 3600) - 24 * days; // 小时 60*60 总小时数-过去的小时数=现在的小时数
        let minutes = parseInt((Number(difftime) % 3600) / 60); // 分钟 -(day*24) 以60秒为一整份 取余 剩下秒数 秒数/60 就是分钟数
        let seconds = parseInt(Number(difftime) % 60); // 以60秒为一整份 取余 剩下秒数
        let time = days + "天" + hours + "时" + minutes + "分" + seconds + "秒"
        console.log(time);

    }
}